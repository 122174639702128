import React from 'react';
import check from '../../../images/icons/ic-done.svg';
import nope from '../../../images/icons/ic-close.svg';

function AccessItem({ access, text }) {
  return (
    <li className='small-body-text text-text-grey-600 flex items-start mb-2'>
      {access ? <span>🟢</span> : <span>🔴</span>}
      {/* <img src={access ? check : nope} className={`mr-4 w-4 inline mt-1`} /> */}
      <span className='pl-4'>{text}</span>
    </li>
  );
}

export default AccessItem;
