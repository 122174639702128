import React, { useState, useEffect } from 'react';
import apiFetch from '../../src/js/fetch';
import BarLoader from 'react-spinners/BarLoader';

function ContactForm() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [feedback, setFeedback] = useState(null);
  useEffect(() => {
    setShow(true); // Wait for component to mount before showing form (mitigating spam bots).
  }, []);

  useEffect(() => {
    if (error) {
      setError(null);
    }
  }, [email, message]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!email || !message) {
      setError('Please fill out all fields.');
      return;
    }

    setError(null);
    setFeedback(null);
    setProcessing(true);

    // send message to backend
    try {
      const sent = await apiFetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contact: {
            email: email,
            message: message,
          },
        }),
      });

      console.log('sent', sent);
      setFeedback('Thanks, your message has been sent!');

      setEmail('');
      setMessage('');
    } catch (error) {
      console.log('Error sending message:', error);
      const err = await error.json();

      setError(err.error_message);
    }

    setProcessing(false);
  };

  if (!show) return null;

  return (
    <form method='POST' className='pt-20' onSubmit={onSubmit}>
      <div className='w-full md:max-w-[35rem] mx-auto bg-background-quaternary rounded-lg p-6 mb-6'>
        <div className='w-full'>
          <label htmlFor='email' className='input-label'>
            E-mail
          </label>
          <input
            type='email'
            name='email'
            id='email'
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
            className='w-full input'
            placeholder='Enter your e-mail address'
          />
        </div>
        <div className='w-full mt-4'>
          <label htmlFor='message' className='input-label'>
            Message
          </label>
          <textarea
            id='message'
            required={true}
            className='w-full h-40'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        {error && <div className='text-text-grey-600 mt-4'>{error}</div>}
        {processing && (
          <div className='mt-4'>
            <BarLoader />
          </div>
        )}
        {feedback && <p className='mt-4 text-text-grey-600'>{feedback}</p>}
      </div>
      <div className='w-full flex md:justify-center'>
        <button className='button dark mx-auto' disabled={processing}>
          Submit
        </button>
      </div>
    </form>
  );
}

export default ContactForm;
