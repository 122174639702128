import React from 'react';
import location from '../../../images/icons/ic-location-dark.svg';
import coordinates from '../../../images/icons/ic-coordinates.svg';
import elevation from '../../../images/icons/ic-elevation.svg';
import SegmentType from '../../SegmentType';
import { formatDuration } from '../../../src/js/lib/duration';
import { formatPosition } from '../../../src/js/lib/position';

const Item = ({ segment, index }) => (
  <a
    href={segment.url}
    className='block 2xl:flex items-start justify-between w-full bg-background-quinary p-3 mb-2 last:mb-0 rounded'
  >
    <div className='flex-1 items-center small-body-text 2xl:flex 2xl:justify-between mb-2 2xl:mb-0'>
      <div className='items-center mb-2 2xl:mb-0 flex lg:block align-middle'>
        <div className='flex items-center mb-2'>
          <span className='pr-2 mini-label text-text-grey-600'>
            S{index + 1}
          </span>
          <SegmentType typeIconUrl={segment.sport_icon_url} />
        </div>
        <h3 className='font-medium small-body-text mb-2 ml-1 md:mb-0 md:ml-0 truncate'>
          {segment.name}
        </h3>
      </div>
      <div className='2xl:border-x border-border-secondary 2xl:w-[10rem] 2xl:flex justify-center'>
        <div className='flex 2xl:block'>
          <p className='mb-0 mr-6 2xl:mr-0'>
            Position:{' '}
            <span className='font-medium'>
              {formatPosition(segment.current_user_segment_effort?.position)}
            </span>
          </p>
          <p className='mb-0'>
            Time:{' '}
            <span className='font-medium'>
              {segment.current_user_segment_effort?.duration
                ? formatDuration(segment.current_user_segment_effort.duration)
                : '-'}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div className='shrink-0 flex flex-col items-end small-body-text 2xl:w-2/5'>
      <div className='flex items-center shrink-0 mb-2'>
        <img src={location} className='w-3 mr-2' />
        <span>{segment.city}</span>
      </div>
      <div className='flex items-center align-middle'>
        <span className='pr-2'>
          <img src={coordinates} className='w-[14px]' />
        </span>
        <span>{(segment.distance_in_meters / 1000).toFixed(2)}</span>
        <span>km</span>
        <span className='pl-2 pr-4 sm:pr-3'>|</span>
        <span className='pr-2'>
          <img src={elevation} className='w-[14px]' />
        </span>
        <span>
          {segment.elevation ? parseFloat(segment.elevation).toFixed(0) : '00'}
        </span>
        <span>m</span>
      </div>
    </div>
  </a>
);

export default Item;
