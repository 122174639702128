import React from 'react';
import apiFetch from '../../../src/js/fetch';
import icClose from '../../../images/icons/ic-exit.svg';
import icLink from '../../../images/icons/ic-link.svg';
import icProfile from '../../../images/icons/ic-profile.svg';
import icCompleted from '../../../images/icons/ic-completed.svg';
import BarLoader from 'react-spinners/BarLoader';
import { event } from '../../../src/js/lib/plausible';

class InviteFriends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      emailField: '',
      error: null,
      contestId: props.contest_id,
      url: props.url,
      copied: false,
      complete: false,
      processing: false,
      modal: props.modal || false,
    };
    this.emailFieldRef = React.createRef();
  }

  onSubmitEmail = (e) => {
    e.preventDefault();

    if (!this.state.emails.includes(this.state.emailField)) {
      this.setState(
        {
          emails: [...this.state.emails, this.state.emailField],
          emailField: '',
        },
        () => {
          this.emailFieldRef.current.focus();
        }
      );
    } else {
      this.setState({
        error: 'Email already added',
      });
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      error: null,
    });
  };

  inviteFriends = async (e) => {
    e.preventDefault();
    const { emails } = this.state;

    if (this.state.emailField) {
      this.setState({
        error:
          'You need to click the "Add to list"-button before sending the invites.',
      });
      return;
    }

    if (emails.length > 0) {
      try {
        this.setState({
          processing: true,
        });
        await Promise.all(emails.map((email) => this.inviteEmail(email)));

        this.setState({
          complete: true,
          processing: false,
        });

        const outsideSkipButton = document.querySelector('.skip-invite-link');
        if (outsideSkipButton) {
          outsideSkipButton.remove();
        }
      } catch (error) {
        alert('Something went wrong. Please try again.');
        this.setState({
          processing: false,
        });
      }
    } else {
      alert('Please add at least one email address.');
    }
  };

  inviteEmail = async (email) => {
    const res = await apiFetch('/api/invite-friend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        challenge_id: this.state.contestId,
      }),
    });

    // Track event in Plausible for each email sent
    event('Challenge - Invite Friend', {
      challenge: this.props.challenge,
    });

    return res;
  };

  removeEmail = (e) => {
    e.stopPropagation(); // Prevents bubbling up to parent div, which could trigger closing the modal.

    const { emails } = this.state;
    const email = e.currentTarget.dataset.email;

    this.setState({
      emails: emails.filter((e) => e !== email),
    });
  };

  copyToClipboard = async (e) => {
    try {
      console.log({ url: this.state.url });
      await navigator.clipboard.writeText(this.state.url);

      this.setState({
        copied: true,
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  render() {
    const { emails, emailField, url, complete, processing, modal } = this.state;
    return (
      <div className='flex-1'>
        {complete ? (
          <div className='w-full'>
            <div className='w-full min-h-[200px] aspect-[5/3] flex justify-center items-center rounded bg-background-quinary'>
              <img src={icCompleted} className='w-[7.5rem] h-[7.5rem]' />
            </div>
            {modal ? null : (
              <div className='mt-4 pt-4 border-t border-border'>
                <a href={url} className='button dark'>
                  Continue to challenge
                </a>
              </div>
            )}
          </div>
        ) : processing ? (
          <div className='w-full'>
            <BarLoader />
          </div>
        ) : (
          <div className='w-full'>
            <form
              method='POST'
              onSubmit={this.onSubmitEmail}
              className='pb-4 mb-4 flex items-center border-b border-border w-full'
            >
              <input
                ref={this.emailFieldRef}
                className='input w-full'
                type='email'
                required={true}
                name='emailField'
                onChange={this.onChange}
                value={emailField}
                placeholder='E-mail address'
              />
              <button
                type='submit'
                className='button bordered ml-2 whitespace-nowrap'
              >
                Add to list
              </button>
            </form>
            <div className='pb-4 mb-4 border-b border-border max-h-[14rem] overflow-y-auto'>
              {emails.length > 0 ? (
                emails.map((email, index) => (
                  <div
                    key={index}
                    className='flex-1 border mb-2 last:mb-0 border-border rounded bg-background-quinary p-3 flex justify-between'
                  >
                    <div className='flex-1 mr-4 flex items-center'>
                      <img
                        src={icProfile}
                        className='w-[1.5rem] h-[1.5rem] mr-4'
                      />
                      <p className='truncate'>{email}</p>
                    </div>
                    <button onClick={this.removeEmail} data-email={email}>
                      <img src={icClose} />
                    </button>
                  </div>
                ))
              ) : (
                <div className='text-sm text-gray-500'>No emails added yet</div>
              )}
              {this.state.error && (
                <div className='text-md text-error mt-2 p-4 bg-background-quinary border border-border-darker-yellow rounded'>
                  {this.state.error}
                </div>
              )}
            </div>
            <div className='flex justify-between items-start'>
              <button
                disabled={this.state.copied}
                onClick={this.copyToClipboard}
                className='button-text h-auto text-text-grey-600 align-middle'
              >
                <img src={icLink} className='inline-block mr-1' />
                {this.state.copied ? 'Copied' : 'Copy link'}
              </button>
              <form method='POST' onSubmit={this.inviteFriends}>
                <button className='button dark'>Invite list</button>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default InviteFriends;
