import React from 'react';
import Modal from '../../Modal';

class PointsScale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      open: false,
      points: props.points_arr,
      defaultPoint: props.points_for_completing_segment_outside_points,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.openModalTrigger = document.querySelector('#open-points-scale');

    this.firstColLength = Math.round(props.points_arr.length / 2);
    this.firstCol = props.points_arr.slice(0, this.firstColLength);
    this.secondCol = props.points_arr.slice(
      this.firstColLength,
      props.points_arr.length
    );
  }

  componentDidMount() {
    console.log('mounted');

    this.openTriggerClickListener = this.openModalTrigger.addEventListener(
      'click',
      this.openModal
    );
  }

  componentWillUnmount() {
    console.log('unmounting');
    if (this.openTriggerClickListener) {
      this.openModalTrigger.removeEventListener('click', this.openModal);
    }
  }

  openModal(e) {
    this.setState({
      open: true,
    });
  }

  closeModal() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { open, points, defaultPoint } = this.state;

    return (
      <Modal
        title=''
        open={open}
        onClose={this.closeModal}
        modalWidth='w-full max-w-[35rem]'
        contentContainerClasses='w-full flex justify-center'
      >
        <div className='flex flex-col mb-8 items-center'>
          <h2 className='body-text text-center mb-4 font-medium'>How we calculate points</h2>
          <p className='mb-12 text-center mini-text md:small-body-text'>Points are awarded on each segment, and count towards the overall leaderboard. If users tie in points, the one with the fastest total time on all segments will rank first.</p>
          <div className='flex w-full justify-center'>
            <div className='pr-2 sm:pr-6 border-r border-border-secondary mr-2 sm:mr-6'>
              <table className='border-collapse table-fixed bg-background-quaternary'>
                <tbody>
                  {this.firstCol.map((val, i) => (
                    <tr key={`val-${val}`}>
                      <td className='bg-background-quaternary'>#{i + 1}</td>
                      <td className='bg-background-quaternary'>
                        <span className='text-text-grey-600 p-2 bg-background-quinary'>
                          {val}
                          {val === 1 ? 'pt' : 'pts'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <table className='border-collapse table-fixed bg-background-quaternary'>
              <tbody>
                {this.secondCol.map((val, i) => (
                  <tr key={`val-${val}`}>
                    <td className='bg-background-quaternary'>
                      #{i + this.secondCol.length + 1}
                    </td>
                    <td className='bg-background-quaternary'>
                      <span className='text-text-grey-600 p-2 bg-background-quinary'>
                        {val}
                        {val === 1 ? 'pt' : 'pts'}
                      </span>
                    </td>
                  </tr>
                ))}
                {!!defaultPoint && (
                  <tr>
                    <td className='bg-background-quaternary'>
                      #{points.length + 1}+
                    </td>
                    <td className='bg-background-quaternary'>
                      <span className='text-text-grey-600 p-2 bg-background-quinary'>
                        {defaultPoint}
                        {defaultPoint === 1 ? 'pt' : 'pts'}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    );
  }
}
export default PointsScale;

/* <div class="flex items-start mb-8 mt-8">
				<% first_column_length = (points_arr.length / 2).round() %>
				<div class="pr-2 sm:pr-6 border-r border-border-secondary mr-2 sm:mr-6">
					<table class="border-collapse table-fixed bg-background-quaternary">
						<tbody>
							<% first_column_length.times do |i| %>
								<tr>
									<td class="bg-background-quaternary">#<%= i + 1 %></td>
									<td class="bg-background-quaternary"><span class="text-text-grey-600 p-2 bg-background-quinary"><%= points_arr[i] %><%= points_arr[i] == 1 ? 'pt' : 'pts' %></span></td>
								</tr>
							<% end %>
						</tbody>
					</table>
				</div>
				<table class="border-collapse table-fixed bg-background-quaternary">
					<tbody>
						<% (points_arr.length - first_column_length).times do |i| %>
							<% adjusted_index = first_column_length + i %>
							<tr>
								<td class="bg-background-quaternary">#<%= adjusted_index + 1 %></td>
								<td class="bg-background-quaternary"><span class="text-text-grey-600 p-2 bg-background-quinary"><%= points_arr[adjusted_index] %><%= points_arr[adjusted_index] == 1 ? 'pt' : 'pts' %></span></td>
							</tr>
						<% end %>
						<% if points_for_completing_segment_outside_points %>
							<tr>
								<td class="bg-background-quaternary">#<%= points_arr.length + 1 %>+</td>
								<td class="bg-background-quaternary"><span class="text-text-grey-600 p-2 bg-background-quinary"><%= points_for_completing_segment_outside_points %><%= points_for_completing_segment_outside_points == 1 ? 'pt' : 'pts' %></span></td>
							</tr>
						<% end %>
					</tbody>
				</table>
			</div> */
