import React from 'react';
import { formatDuration } from '../../src/js/lib/duration';
import { formatNumber } from '../../src/js/lib/number';
import { addTippys } from '../../src/js/tooltips';
import BarLoader from 'react-spinners/BarLoader';
import Pagination from '../Pagination';
import apiFetch from '../../src/js/fetch';
import moment from 'moment/moment';

class ContestShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: props.segments,
      contestId: props.contest_id,
      userId: props.user_id,
      contestants: [],
      loading: true,

      numPages: 1,
      currentPage: 1,
    };
  }

  componentDidMount() {
    // console.log({state: this.state});
    addTippys();

    this.fetchContestants(this.state.currentPage);

    window.reloadLeaderboard = this.reloadLeaderboard;
  }

  reloadLeaderboard = () => {
    this.setState({ currentPage: 1, contestants: [], loading: true }, () => {
      this.fetchContestants(this.state.currentPage);
    });
  };

  componentWillUnmount() {
    window.reloadLeaderboard = null;
  }

  onPaginate = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchContestants(page);
    });
  };

  fetchContestants = async (page) => {
    this.setState({ loading: true });

    const { contestId } = this.state;
    try {
      const data = await apiFetch(`/api/challenges/leaderboard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contest_id: contestId,
          page: page,
        }),
      });

      console.log({ data });

      this.setState(
        {
          contestants: data.contestants,
          numPages: data.total_pages,
        },
        () => {
          addTippys();
        }
      );
    } catch (e) {
      console.log({ e });
      const err = e.json ? await e.json() : null;
      console.log({ err });

      this.setState({
        loading: false,
        error:
          (err && err.error_message) ||
          'Something went wrong. Please try again later.',
      });
    }
    this.setState({ loading: false });
  };

  render() {
    const { segments, contestants, numPages, currentPage, userId } = this.state;

    return (
      <div className='w-full'>
        <h2 className='mini-label font-medium'>Leaderboard</h2>
        <div className='w-full overflow-x-auto challenge-leaderboard'>
          <table className='w-full border-spacing-y-2 border-separate'>
            <thead className=''>
              <tr className=''>
                <th className=''>#</th>
                <th className=''>Challenger</th>
                {segments.map((segment, i) => (
                  <th key={`${segment.id}`} className='relative items-center'>
                    <span className='inline-flex items-center'>
                      S{i + 1}{' '}
                      <a
                        href={segment.url}
                        className='inline-block pl-1 mini-text text-text-grey-600 whitespace-nowrap underline hover:no-underline underline-offset-2'
                      >
                        {segment.name}
                      </a>
                    </span>
                  </th>
                ))}
                <th className=''>Points</th>
              </tr>
            </thead>
            <tbody>
              <tr className='h-2' data-spacer></tr>

              {contestants.map((contestant, i) => (
                <tr
                  key={contestant.id}
                  className={`${
                    contestant.user_id === userId
                      ? 'current-user font-medium'
                      : ''
                  }`}
                >
                  <td className=''>
                    {contestant?.position && contestant.position > 0
                      ? `${contestant.position}.`
                      : '-'}
                  </td>
                  <td className=''>{contestant.user.name}</td>
                  {segments.map((segment, j) => {
                    const effort = contestant.contest_segment_efforts.find(
                      (cse) => cse.segment_id === segment.id
                    );
                    const pos = effort?.position;
                    // console.log({effort, contestant});
                    let tippyContent = '';
                    if (effort) {
                      tippyContent = `<div class="flex justify-between mb-4"><span class="pr-8 font-medium small-body-text">${contestant.user.name}</span><span class="mini-text">${effort.points}pts</span></div>`;
                      tippyContent += `<div class="grid grid-cols-2 gap-x-6 gap-y-1 mini-text text-left">`;
                      tippyContent += `<span><span class="font-medium">Time:</span> ${formatDuration(
                        effort.duration
                      )}</span>`;
                      if (effort.avg_power_watts)
                        tippyContent += `<span><span class="font-medium">Watt:</span> ${effort.avg_power_watts.toFixed(
                          0
                        )}</span>`;
                      if (effort.speed)
                        tippyContent += `<span><span class="font-medium">Speed:</span> ${effort.speed} km/h</span>`;
                      if (effort.pace)
                        tippyContent += `<span><span class="font-medium">Pace:</span> ${formatDuration(
                          effort.pace
                        )} /km</span>`;
                      if (
                        effort.power_watt_per_kg &&
                        effort.power_watt_per_kg.toFixed
                      )
                        tippyContent += `<span><span class="font-medium">W/kg:</span> ${effort.power_watt_per_kg.toFixed(
                          1
                        )}</span>`;

                      if (effort.completed_at) {
                        tippyContent += `<span class="col-span-2">${moment(
                          effort.completed_at
                        ).format('MMM DD, YYYY')}</span>`;
                      }
                      tippyContent += `</div>`;
                    } else {
                      tippyContent = null;
                    }
                    return (
                      <td key={`${contestant.id}-${segment.id}`} className=''>
                        <span
                          className={`${effort ? 'cursor-pointer' : ''} p-2`}
                          data-show-arrow='true'
                          data-tippy-content={tippyContent}
                          data-tippy-placement='right'
                        >
                          {pos ? `#${pos}` : '-'}
                          {effort && effort.duration ? (
                            <>
                              <span className='small-body-text text-text-grey-600'>
                                {' '}
                                - {formatDuration(effort.duration)}
                              </span>
                            </>
                          ) : null}
                        </span>
                        {effort && effort.duration ? (
                          <button
                            className='report-button mini-text text-text-grey-600 ml-2'
                            data-reportable-type='SegmentEffort'
                            data-reportable-id={effort.segment_effort_id}
                          >
                            Report
                          </button>
                        ) : null}
                      </td>
                    );
                  })}
                  <td className=''>{formatNumber(contestant.points)}pts</td>
                </tr>
              ))}
            </tbody>
          </table>

          {this.state.loading ? <BarLoader /> : null}

          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            onPaginate={this.onPaginate}
          />
        </div>
      </div>
    );
  }
}
export default ContestShow;
