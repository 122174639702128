import MapHandler from '../mapHandler';

import 'leaflet-ruler/src/leaflet-ruler.js';
import 'leaflet-ruler/src/leaflet-ruler.css';

export default class DebugActivity extends MapHandler {
  constructor(options = {}) {
    super(options);

    this.segmentLayers = [];

    this.addDebugMarkersToMap = this.addDebugMarkersToMap.bind(this);

    L.control
      .ruler({
        position: 'topright',
        lengthUnit: {
          factor: 1000, // from km to m
          display: 'meters',
          decimal: 2,
        },
      })
      .addTo(this.map);
  }

  // this.drawPolyline(this.coordinates, false, {
  //   color: "#2C3232",
  //   opacity: this.selectControlEnabled ? .6 : 1,
  //   weight: 1
  // });

  /**
   * =========================================================
   * Debug
   */
  addDebugMarkersToMap(samples, type = 'activity', details) {
    // Add new segments
    samples.forEach(async (sample, index) => {
      const markerPopup = L.popup().setContent(
        type === 'activity'
          ? `${details ? `${details}</br>` : ''}time_in_seconds: ${
              sample.time_in_seconds
            }<br /> elapsed_time: ${sample.elapsed_time}<br /> lat: ${
              sample.lat
            }<br /> lng: ${sample.lng}<br /> altitude: ${
              sample.altitude
            }<br /> speed: ${sample.speed}<br /> power: ${
              sample.power
            }<br /> manual: ${sample.manual}<br /> heart_rate: ${
              sample.heart_rate
            }`
          : `${details ? `${details}</br>` : ''}lat: ${sample.lat}<br /> lng: ${
              sample.lng
            }<br /> altitude: ${sample.altitude}<br />index: ${index}`
      );

      this.addMarker(
        L.latLng(sample.lat, sample.lng),
        {
          color:
            type === 'activity'
              ? sample.manual
                ? '#00ff33'
                : '#3388ff'
              : '#ff0000',
        },
        markerPopup
      );
    });
  }

  addMarker(latLng, options, markerPopup) {
    var circleMarker = L.circleMarker(latLng, options)
      .bindPopup(markerPopup)
      .addTo(this.map);
  }
}
