import React from 'react';
import ReactPaginate from 'react-paginate';

function Pagination({
  numPages,
  currentPage,
  onPaginate,
  loading = false,
  initialPage = 0,
}) {
  if (numPages < 2) return null;

  const handlePageClick = (data) => {
    onPaginate(data.selected + 1);
  };
  const handleClick = () => {
    if (loading) return false; // Prevent click if loading
  };

  return (
    <ReactPaginate
      initialPage={initialPage}
      breakLabel='...'
      nextLabel=''
      onClick={handleClick}
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      pageCount={numPages}
      breakClassName='page'
      pageClassName='page'
      activeClassName='current'
      nextLinkClassName='next'
      previousLinkClassName='prev'
      nextClassName=''
      previousClassName=''
      containerClassName='pagination mt-8'
      marginPagesDisplayed={3}
      previousLabel=''
    />
  );
}

export default Pagination;
